import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { NEW_WORKER_POST, EDIT_WORKER_POST } from '../interfaces/worker';
import { AlertService } from './alert.service';
import { EDIT_ADDRESS_POST, NEW_ADDRESS_POST } from '../interfaces/customer';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  /* 通信先 */
  url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { AlertService } alertService
   */
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  /**
   * サーバーから作業者一覧を取得
   * ソート：user__email, user__name, user__name_kana
   * 検索：user__email, user__name, user__name_kana, organization__org_name, organization__org_name_kana
   * 戻り：WORKER_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getWorkerList(p?: number, s?: number, ordering?: string, search?: string): Observable<any> {

    var params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get(`${this.url}/user/worker`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業者情報詳細を取得
   *
   * 戻り：WORKER
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workerID        作業者ID
   * @returns { Observable<any> }       結果
   * @throws { any }                    エラーメッセージ
   */
  getWorkerDetail(workerID: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/user/worker/${workerID}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業者を新規作成する
   *
   * 戻り：NEW_WORKER_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { NEW_WORKER_POST } postdata      ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  newWorker(postdata: NEW_WORKER_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/worker/new`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業者を編集する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workerID            コールセンターID
   * @param { EDIT_WORKER_POST } postdata   ポストデータ
   * @returns { Observable<any> }               結果
   * @throws { any }                            エラーメッセージ
   */
  editWorker(workerID: number, postdata: EDIT_WORKER_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/worker/${workerID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業者を削除する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workerID        作業者ID
   * @returns { Observable<any> }       結果
   * @throws { any }                    エラーメッセージ
   */
  deleteWorker(workerID: number): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/worker/${workerID}/delete`, null)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * サーバーから会社一覧を取得
   *
   * ソート：id, org_name
   * 検索： org_name, org_name_kana, phone_number, address__postal_code, address__prefecture
   * 戻り：COMPANY_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } p          ページ番号(1〜)
   * @param { number } s          1ページあたりの取得数
   * @param { string } ordering   表示順
   * @param { string } search     検索文字列
   * @returns { Observable<any> } 結果
   * @throws { any }              エラーメッセージ
   */
  getCompanyList(p?: number, s?: number, ordering?: string, search?: string): Observable<any> {

    var params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    // パラメータ付き検索
    return this.httpClient.get(`${this.url}/user/company`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者の住所を新規作成する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workerID             作業者ID
   * @param { NEW_ADDRESS_POST } postdata     ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  newAddress(workerID: number, postdata: NEW_ADDRESS_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/worker/${workerID}/address/new`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者の住所を編集する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workerID               作業者ID
   * @param { number } addressID                住所ID
   * @param { EDIT_ADDRESS_POST } postdata      ポストデータ
   * @returns { Observable<any> }               結果
   * @throws { any }                            エラーメッセージ
   */
  editAddress(workerID: number, addressID: number, postdata: EDIT_ADDRESS_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/worker/${workerID}/address/${addressID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 依頼者の住所を削除する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workerID               作業者ID
   * @param { number } addressID                住所ID
   * @returns { Observable<any> }               結果
   * @throws { any }                            エラーメッセージ
   */
  deleteAddress(workerID: number, addressID: number): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/worker/${workerID}/address/${addressID}/delete`, null)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }
}
