import { Component, ViewChild } from '@angular/core';
import { IonContent, LoadingController, ModalController } from '@ionic/angular';
import { CUSTOMER, CUSTOMER_RESPONSE } from '../../../interfaces/customer';
import { CustomerService } from '../../../providers/customer.service';

@Component({
  selector: 'app-modal-select-customer',
  templateUrl: './modal-select-customer.page.html',
  styleUrls: ['./modal-select-customer.page.scss'],
})
export class ModalSelectCustomerPage {

  @ViewChild(IonContent, { static: false }) content: IonContent;

  /* 画面制御用 */
  customers: CUSTOMER[] = [];           // 検索結果
  select: CUSTOMER;                     // クリックされた依頼者
  searchKey: string = "";               // 検索ワード
  show: number = 100000;                // 表示件数（全件取得したいので暫定10万件）
  page: number = 1;                     // 何ページ目

  /**
   * Constructor
   *
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { CustomerService } customerService
   */
  constructor(
    private loadingController: LoadingController,
    private modalController: ModalController,
    private customerService: CustomerService,
  ) { }

  /**
   * 画面表示時にデータを取得
   *
   */
  async ionViewWillEnter() {
    await this.loadData();
  }

  /**
   * サーバーからデータを取得し、画面制御用の変数に値を設定する
   *
   */
  async loadData() {

    // API設定
    const search = (this.searchKey !== "" ? this.searchKey : null);

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // サーバーに問い合わせ
    this.customerService.getCustomerList(this.page, this.show, null, search).subscribe(
      res => {
        const response = res as CUSTOMER_RESPONSE;
        this.customers = response.results;
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：getCustomerList", error);
      }
    );
  }

  /**
   * クリックされた依頼者の情報を表示用変数に格納
   *
   * @param { CUSTOMER } target クリックされた依頼者
   *
   */
  showDetail(target: CUSTOMER) {
    this.select = target;
    this.content.scrollToTop(1000);
  }

  /**
   * 選択された依頼者の情報を戻り値にセットして、画面を閉じる
   *
   */
  setCustomer() {
    this.modalController.dismiss({ customer: this.select });
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
