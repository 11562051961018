import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ToastController, ModalController } from '@ionic/angular';

import { SystemConst } from '../../../app-constant';
import { COMMON_RESPONSE } from '../../../interfaces/common';
import { NEW_CUSTOMER_RESPONSE } from '../../../interfaces/customer';
import { CustomerService } from '../../../providers/customer.service';
import { CustomValidator } from '../../../validators/custom-validator';

@Component({
  selector: 'app-modal-customer',
  templateUrl: './modal-customer.page.html',
  styleUrls: ['./modal-customer.page.scss'],
})
export class ModalCustomerPage implements OnInit {

  /* パラメータ */
  @Input() customerID: number;
  @Input() customer_name: string;
  @Input() customer_name_kana: string;
  @Input() phone_number: string;
  @Input() email: string;
  @Input() debtor_company_name: string;
  @Input() debtor_company_no: string;
  @Input() debtor_family_name: string;
  @Input() debtor_first_name: string;

  /* 画面制御 */
  customerForm: FormGroup;
  isSubmitted: boolean = false;

  /**
   * Constructor
   *
   * @param { FormBuilder } formBuilder
   * @param { AlertController } alertController
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { ToastController } toastController
   * @param { CustomerService } customerService
   */
  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private toastController: ToastController,
    private customerService: CustomerService
  ) { }


  /**
   * ngOnInit
   * フォームの設定
   *
   */
  ngOnInit() {
    this.customerForm = this.formBuilder.group({
      customer_name: [this.customer_name, Validators.compose([Validators.maxLength(200), Validators.required])],
      customer_name_kana: [this.customer_name_kana, Validators.compose([Validators.maxLength(200), Validators.required, CustomValidator.katakana])],
      phone_number: [this.phone_number, Validators.compose([Validators.minLength(10), Validators.maxLength(15), Validators.pattern('^[0-9()-]+$')])],
      email: [this.email, Validators.email],
      debtor_company_name: [this.debtor_company_name, Validators.compose([Validators.maxLength(200),])],
      debtor_company_no: [this.debtor_company_no, Validators.maxLength(200)],
      debtor_family_name: [this.debtor_family_name, Validators.compose([Validators.maxLength(200),])],
      debtor_first_name: [this.debtor_first_name, Validators.compose([Validators.maxLength(200),])],
    });
  }

  /**
   * エラーフィールドを返却
   *
   */
  get errorControl() {
    return this.customerForm.controls;
  }

  /**
   * 登録ボタンを押された時の処理
   * 入力エラーがある場合はそのままreturn
   * 依頼者IDがある時は編集、ない場合は登録を呼び出し
   *
   */
  async submitForm() {

    this.isSubmitted = true;

    // エラーの時は何もしない
    if (!this.customerForm.valid) {
      return false;
    }

    // アラート表示
    const alert = await this.alertController.create({
      header: this.customerID ? '編集' : '新規登録',
      message: this.customerID ? '顧客の情報を変更します' : '顧客を新規に登録します',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            if (this.customerID) {
              this.editCustomer();
            } else {
              this.newCustomer();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * 依頼者を追加する
   *
   */
  async newCustomer() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.customerService.newCustomer(this.customerForm.value).subscribe(
      res => {
        loading.dismiss();
        const response = res as NEW_CUSTOMER_RESPONSE;
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.showToast("登録しました");
          this.modalController.dismiss({ customerID: response.customer_id });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：newCustomer", error);
      }
    );
  }

  /**
   * 依頼者を編集する
   *
   */
  async editCustomer() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.customerService.editCustomer(this.customerID, this.customerForm.value).subscribe(
      res => {
        loading.dismiss();
        const response = res as COMMON_RESPONSE;
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.showToast("編集しました");
          this.modalController.dismiss({ customerID: this.customerID });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：editCustomer", error);
      }
    );
  }

  /**
   * 登録完了のトーストを表示
   *
   * @param { string } msg 表示するメッセージ
   *
   */
  async showToast(msg: string) {
    const toast = await this.toastController.create({
      color: 'tertiary',
      position: 'middle',
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
