export const environment = {
  production: true,

  //apiUrl: 'https://api.pruuune.com/d0/',
  //apiDomain: 'api.pruuune.com',

  apiUrl: 'https://naapi.pic-dev.com/d0',
  apiDomain: 'naapi.pic-dev.com',

  //googleMapsAPIKey: 'AIzaSyBigWFqdINznEZYLHmKTW7dWY4EcnSXiYM'
  googleMapsAPIKey: 'AIzaSyBRiyb2d2Y2mBKkqgFvieUuivgGml6RY8w'
};
