import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

// カレンダー関連
import { NgCalendarModule } from 'ionic2-calendar';
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Storage, IonicStorageModule } from '@ionic/storage';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AgmCoreModule } from '@agm/core';

import { environment } from '../environments/environment';
import { SystemConst } from './app-constant';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { SharedDirectivesModule } from './directives/shared-directives.module';

import { ModalCallcenterPageModule } from './pages/callcenter/modal-callcenter/modal-callcenter.module';
import { ModalSelectCheckitemPageModule } from './pages/checklist/modal-select-checkitem/modal-select-checkitem.module';
import { ModalAddressPageModule } from './pages/customer/modal-address/modal-address.module';
import { ModalCustomerPageModule } from './pages/customer/modal-customer/modal-customer.module';
import { ModalSelectCustomerPageModule } from './pages/customer/modal-select-customer/modal-select-customer.module';
import { ModalImageUploadPageModule } from './pages/report/modal-image-upload/modal-image-upload.module';
import { ModalPreviewPageModule } from './pages/report/modal-preview/modal-preview.module';
import { ModalSelectSupportPageModule } from './pages/support/modal-select-support/modal-select-support.module';
import { ModalEditSupportPageModule } from './pages/support/modal-edit-support/modal-edit-support.module';
import { ModalCalendarPageModule } from './pages/work/modal-calendar/modal-calendar.module';
import { ModalEditWorkPageModule } from './pages/work/modal-edit-work/modal-edit-work.module';
import { ModalWorkerPageModule } from './pages/worker/modal-worker/modal-worker.module';
import { ModalSelectWorkerPageModule } from './pages/worker/modal-select-worker/modal-select-worker.module';
import { ModalAddressWorkerPageModule } from './pages/worker/modal-address/modal-address.module';

// auth通信の設定
export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get(SystemConst.TOKEN_KEY);
    },
    whitelistedDomains: [environment.apiDomain],
    authScheme: 'JWT '
  }
}

// カレンダー関連
registerLocaleData(localeJa);

@NgModule({
  declarations: [
    AppComponent
  ],

  entryComponents: [

  ],

  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),

    /* カレンダー */
    NgCalendarModule,

    /* JWTの設定 */
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage],
      }
    }),

    /* googleMapの設定 */
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsAPIKey
    }),

    /* ルーティング */
    AppRoutingModule,

    /* ディレクティブ*/
    SharedDirectivesModule,

    /* モーダル画面 */
    ModalCallcenterPageModule,
    ModalSelectCheckitemPageModule,
    ModalAddressPageModule,
    ModalAddressWorkerPageModule,
    ModalCustomerPageModule,
    ModalSelectCustomerPageModule,
    ModalImageUploadPageModule,
    ModalPreviewPageModule,
    ModalSelectSupportPageModule,
    ModalEditSupportPageModule,
    ModalCalendarPageModule,
    ModalEditWorkPageModule,
    ModalWorkerPageModule,
    ModalSelectWorkerPageModule
  ],

  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'ja-JP' }
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
