import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';

import { SystemConst } from '../../../app-constant';
import { ADDRESS, COMMON_RESPONSE } from '../../../interfaces/common';
import { EDIT_ADDRESS_POST, NEW_ADDRESS_POST } from '../../../interfaces/customer';
import { WorkerService } from '../../../providers/worker.service';
import { MapService } from '../../../providers/map.service';

@Component({
  selector: 'app-modal-address',
  templateUrl: './modal-address.page.html',
  styleUrls: ['./modal-address.page.scss'],
})
export class ModalAddressPage implements OnInit {

  /* パラメータ */
  @Input() workerID: number;
  @Input() address: ADDRESS;

  /* 画面制御 */
  addressForm: FormGroup;
  isSubmitted: boolean = false;

  /**
   * Constructor
   *
   * @param { FormBuilder } formBuilder
   * @param { AlertController } alertController
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { WorkerService } workerService
   * @param { MapService } mapService
   *
   */
  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private workerService: WorkerService,
    private mapService: MapService
  ) { }

  /**
   * ngOnInit
   * フォームの設定
   *
   */
  ngOnInit() {
    if (this.address) {
      this.addressForm = this.formBuilder.group({
        id: [this.address.id],
        country: [this.address.country, Validators.required],
        postal_code: [this.address.postal_code, Validators.compose([Validators.required, Validators.pattern('^[0-9-]+$')])],
        prefecture: [this.address.prefecture, Validators.required],
        address1: [this.address.address1, Validators.required],
        address2: [this.address.address2]
      });
    } else {
      this.addressForm = this.formBuilder.group({
        id: [''],
        country: ['日本', Validators.required],
        postal_code: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9-]+$')])],
        prefecture: ['', Validators.required],
        address1: ['', Validators.required],
        address2: ['']
      });
    }
  }

  /**
   * エラーフィールドを返却
   *
   */
  get errorControl() {
    return this.addressForm.controls;
  }

  /**
   * 登録ボタンを押された時の処理
   * 入力エラーがある場合はそのままreturn
   * 依頼者IDがある時は編集、ない場合は登録を呼び出し
   *
   */
  async submitForm() {

    this.isSubmitted = true;

    // エラーの時は何もしない
    if (!this.addressForm.valid) {
      return false;
    }

    // アラート表示
    const alert = await this.alertController.create({
      header: this.address ? '編集' : '　新規登録',
      message: this.address ? '住所を変更します' : '住所を新規に登録します',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            if (this.address) {
              this.editAddress();
            } else {
              this.newAddress();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * 住所を追加する
   *
   */
  async newAddress() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // ポストデータ
    let postData: NEW_ADDRESS_POST = {
      country: this.addressForm.value.country,
      postal_code: this.addressForm.value.postal_code,
      prefecture: this.addressForm.value.prefecture,
      address1: this.addressForm.value.address1,
    };
    if (this.addressForm.value.address2) {
      postData.address2 = this.addressForm.value.address2;
    }
    // ジオコーディング
    // const result = await this.mapService.geocoding((this.addressForm.value.prefecture + this.addressForm.value.address1) + (this.addressForm.value.address2 ? this.addressForm.value.address2 : ""));
    // if (result) {
    //   let location = result[0].geometry.location;
    //   postData.latitude = location.lat();
    //   postData.longitude = location.lng();
    // }

    postData.latitude = 35.685175;
    postData.longitude = 139.7506108;

    this.workerService.newAddress(this.workerID, postData).subscribe(
      res => {
        loading.dismiss();
        const response = res as COMMON_RESPONSE;
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.modalController.dismiss({ workerID: this.workerID });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：newAddress", error);
      }
    );
  }

  /**
   * 依頼者を編集する
   *
   */
  async editAddress() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    // ポストデータ
    let postData: EDIT_ADDRESS_POST = {
      country: this.addressForm.value.country,
      postal_code: this.addressForm.value.postal_code,
      prefecture: this.addressForm.value.prefecture,
      address1: this.addressForm.value.address1,
    };
    if (this.addressForm.value.address2) {
      postData.address2 = this.addressForm.value.address2;
    }
    // ジオコーディング
    // const result = await this.mapService.geocoding((this.addressForm.value.prefecture + this.addressForm.value.address1) + (this.addressForm.value.address2 ? this.addressForm.value.address2 : ""));
    // if (result) {
    //   let location = result[0].geometry.location;
    //   postData.latitude = location.lat();
    //   postData.longitude = location.lng();
    // }
    postData.latitude = 35.685175;
    postData.longitude = 139.7506108;

    this.workerService.editAddress(this.workerID, this.addressForm.value.id, postData).subscribe(
      res => {
        loading.dismiss();
        const response = res as COMMON_RESPONSE;
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.modalController.dismiss({ workerID: this.workerID });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：editAddress", error);
      }
    );
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
